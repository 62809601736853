<template>
    <div class="services-container">
        <h2>{{ $t('services.header') }}</h2>

        <div class="filter-buttons">
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                :class="{ active: archived == false }"
                @click="toggleArchived(false)"
            >
                {{ $t('services.current') }}
            </button>
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                :class="{ active: archived == true }"
                @click="toggleArchived(true)"
            >
                {{ $t('services.archived') }}
            </button>
        </div>

        <div v-if="services.length === 0" class="no-services-message">
            <p>{{ $t('services.no_services') }}</p>
        </div>

        <div v-else class="services-list">
            <div class="list-header">
                <span>Name</span>
                <span>{{ $t('services.type') }}</span>
                <span>{{ $t('services.price') }}</span>
                <span>{{ $t('services.actions') }}</span>
            </div>
            <ul>
                <li v-for="service in services" :key="service.attributes.id" class="list-item">
                    <span v-if="service.attributes.type == 'ssl'">{{ service.attributes.params.ssl_type }}</span>
                    <span v-if="service.attributes.type == 'dns'">{{ service.attributes.params.domain_name }}</span>
                    <span v-if="service.attributes.type == 'support'">{{
                        service.attributes.params.support_type
                    }}</span>
                    <span v-if="service.attributes.type == 'hosting'">{{ service.attributes.params.app_name }}</span>
                    <span v-if="service.attributes.type == 'custom'">{{ service.attributes.params.name }}</span>
                    <span>{{ $t(`company_services.types.${service.attributes.type}`) }}</span>
                    <span>{{ service.attributes.amount_format }}</span>
                    <div class="item-buttons">
                        <button
                            class="view-details-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                            @click="$router.push({ path: `/company/services/view/${service.id}` })"
                        >
                            <i class="at-btn__icon icon icon-eye"></i>
                            {{ $t('control.view') }}
                        </button>
                        <button
                            class="view-details-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                            @click="openDeleteModal(service)"
                        >
                            <i class="at-btn__icon icon icon-trash-2"></i>
                            {{ $t('control.remove') }}
                        </button>
                    </div>
                </li>
            </ul>
        </div>

        <DeleteModal
            :visible="isModalVisible"
            :message="$t('delete_modal.message')"
            @confirm="confirmDelete"
            @close="closeDeleteModal"
        />

        <div v-if="services.length > 0" class="pagination">
            <at-pagination
                :total="totalItems"
                :current="page"
                :page-size="itemsPerPage"
                class="crud__pagination"
                @page-change="onPageChange"
            >
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
    </div>
</template>

<script>
    import ClientService from '../../../Client/services/client.service';
    import CompanyServicesService from '../../../CompanyServices/services/company-services.service';
    import RolePolicy from '../../../../policies/role.policy';
    import DeleteModal from '@/components/DeleteModal.vue';

    export default {
        components: {
            DeleteModal,
        },
        data() {
            return {
                clientID: this.$route.params.id,
                clientObject: null,
                services: [],
                clientService: new ClientService(),
                companyService: new CompanyServicesService(),
                isModalVisible: false,
                serviceToDelete: null,
                archived: false,

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,

                queryParams: {
                    page: this.$route.query.page,
                },
            };
        },
        computed: {
            canAccessRemove() {
                return RolePolicy.haveRole(['admin']);
            },
        },
        async created() {
            await this.fetchClient();
            await this.fetchCompanyOrderedServices();
        },
        methods: {
            async fetchCompanyOrderedServices() {
                try {
                    const res = await this.companyService.getAll({
                        client_company_id: this.clientObject.company_id,
                        page: this.queryParams.page,
                        archived: this.archived,
                    });

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.services = data.map(item => ({
                        ...item,
                        attributes: {
                            ...item.attributes,
                            params: JSON.parse(item.attributes.params),
                        },
                    }));
                } catch (error) {
                    console.log('Error fetching company ordered services', error);
                }
            },
            toggleArchived(value) {
                this.archived = value;
                this.page = 1;
                this.fetchCompanyOrderedServices();
            },
            async fetchClient() {
                try {
                    const { data } = await this.clientService.getItem(this.clientID);
                    this.clientObject = data.data.attributes;
                } catch (error) {
                    console.log('Error fetching client data', error);
                }
            },
            getServiceTitle(service) {
                return `Service Type: ${this.$t(`company_services.types.${service.attributes.type}`)}`;
            },
            openDeleteModal(service) {
                this.serviceToDelete = service;
                this.isModalVisible = true;
            },
            closeDeleteModal() {
                this.isModalVisible = false;
                this.serviceToDelete = null;
            },
            async confirmDelete() {
                try {
                    await this.companyService.update(this.serviceToDelete.attributes.id, {
                        client_service: { archived: true },
                    });
                    this.serviceToDelete.attributes.archived = true;
                    this.services = this.services.filter(
                        service => service.attributes.id != this.serviceToDelete.attributes.id,
                    );
                } catch (error) {
                    console.log('Error deleting service', error);
                } finally {
                    this.closeDeleteModal();
                }
            },
            viewService(service) {
                this.$router.push({
                    name: 'client-services.view',
                    params: { companyServiceID: service.attributes.id },
                });
            },
            async onPageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return await this.fetchCompanyOrderedServices();
            },
        },
    };
</script>

<style scoped lang="scss">
    .services-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .list-header,
        .list-item {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr;
            gap: 10px;
            align-items: center;
            padding: 12px 15px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background-color: #f9f9f9;

            .theme-dark & {
                background-color: #444;
                color: #ffa500;
                border-color: #333;
            }
        }

        .list-header {
            margin-top: 10px;
            font-weight: bold;
            background-color: #f4f4f4;
            border: none;

            .theme-dark & {
                background-color: #555;
            }
        }

        .list-item {
            margin: 5px 0 5px 0;
            transition: background-color 0.3s ease;
        }

        .list-item:hover {
            background-color: #f1f1f1;
            cursor: pointer;

            .theme-dark & {
                background-color: #555;
            }
        }

        .item-buttons {
            & button {
                margin-right: 10px;
            }
        }
    }

    .filter-buttons {
        display: flex;
        justify-content: space-between;
    }

    .pagination {
        margin: 15px 0;
        display: flex;
        justify-content: flex-end;
    }

    .buttons {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .active {
        color: #ffa500;

        .theme-dark & {
            color: #c4c4cf;
            border-color: #c4c4cf;
        }
    }

    .no-services-message {
        font-size: 1.2em;
        color: #777;
        padding: 20px;
    }
</style>
